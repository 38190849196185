import React, { useRef, useEffect, useState } from 'react'
import bem from 'bem'
import css from './DbIframe.module.scss'
import Icon from 'components/Icon'

const b = bem.dbIframe(css)

const DbIframe = props => {
	const [isIframeLoaded, setIsIframeLoaded] = useState(false)
	const iframeRef = useRef(null)
	const { name, page } = props
	const url = `/s/db-preview/${name}.xlsx/${page}.html`

	useEffect(() => {
		iframeRef.current.addEventListener('load', e => {
			setIsIframeLoaded(true)
		})
	}, [page])

	return (
		<div className={b({ 'is-loaded': isIframeLoaded })}>
			<div className={b('loader')}>
				<Icon className={b('loader-excel')} type="excel" />
				<Icon className={b('loader-loading')} type="loading" />
				<p>Открываем таблицу &laquo;{page}&raquo;</p>
				<p>Почти готово...</p>
			</div>
			<iframe ref={iframeRef} className={b('iframe')} src={url} title={`Пример базы. Таблица "${page}"`} />
		</div>
	)
}

export default DbIframe
