import React, { useState } from 'react'
import bem from 'bem'
import css from './TableList.module.scss'
import PropTypes from 'prop-types'
import Button from 'components/Button'

const b = bem.tableList(css)

const TableList = props => {
	const { items, limit } = props
	const [isShow, setIsShow] = useState(false)
	return (
		<div className={b()}>
			<table className={b('table')}>
				<tbody>
					{items.map(({ key, value }, index) => {
						return (
							<tr key={key} className={b({ 'is-hidden': !isShow && limit && index + 1 > limit })}>
								<td className={b('key')} dangerouslySetInnerHTML={{ __html: key }} />
								<td className={b('value')} dangerouslySetInnerHTML={{ __html: value }} />
							</tr>
						)
					})}
				</tbody>
			</table>
			{!!(limit && items.length > limit && !isShow) && (
				<div className="text-center mt-lg">
					<Button size="sm" onClick={() => setIsShow(true)}>
						Показать еще {items.length - limit}
					</Button>
				</div>
			)}
		</div>
	)
}

TableList.defaultProps = {
	limit: 0,
}

TableList.propTypes = {
	limit: PropTypes.number,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
}

export default TableList
