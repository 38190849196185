import React from 'react'
import bem from 'bem'
import css from './Columns.module.scss'

const b = bem.columns(css)

const Columns = ({ count, children }) => {
	return <div className={b({ count })}>{children}</div>
}

Columns.defaultProps = {
	count: 3,
}

export default Columns
