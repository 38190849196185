import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import ButtonScroll from 'components/ButtonScroll'
import ymReachGoal from 'utils/ymReachGoal'

const ButtonScrollDownload = ({ scrollRef, link, names, buttonThemeAfter, buttonThemeBefore, delay, ...rest }) => {
	const [status, setStatus] = useState('default')
	return (
		<>
			{status === 'success' && (
				<ButtonScroll theme={buttonThemeAfter} icon="excel" size="lg" to={scrollRef} on="top" {...rest}>
					Купить базу
				</ButtonScroll>
			)}
			{['default', 'loading'].indexOf(status) !== -1 && (
				<Button
					theme={buttonThemeBefore || 'primary'}
					icon={status === 'default' ? 'excel' : 'loading'}
					size="lg"
					to={link}
					onClick={e => {
						if (status !== 'default') {
							e.preventDefault()
							return
						}
						ymReachGoal('db-download')
						setStatus('loading')
						setTimeout(() => setStatus('success'), delay)
					}}
					title={`Скачать демонстрационную версию базы ${names.mn.rd} в Excel-файле`}
					isLinkNative
					download
					rel="nofollow noopener noreferrer"
					{...rest}
				>
					Скачать демо
				</Button>
			)}
		</>
	)
}

ButtonScrollDownload.defaultProps = {
	buttonThemeBefore: 'primary',
	buttonThemeAfter: 'success',
	delay: 12 * 1000,
}

ButtonScrollDownload.propTypes = {
	buttonThemeBefore: PropTypes.oneOf(['primary', 'success', 'light']),
	buttonThemeAfter: PropTypes.oneOf(['primary', 'success', 'light']),
	scrollRef: PropTypes.object.isRequired,
	link: PropTypes.string.isRequired,
	delay: PropTypes.number,
	names: PropTypes.shape({
		mn: PropTypes.shape({
			rd: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
}

export default ButtonScrollDownload
