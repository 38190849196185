import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const WithDbCompany = Component => {
	const Enhanced = props => {
		// prettier-ignore
		const images = useStaticQuery(graphql`
			query {
				allFile(
					 filter: {
						  sourceInstanceName: { eq: "images" },
						  relativeDirectory: { eq: "db-company" },
                    name: {
                        nin: [
									 "notary",
									 "notary-email",
									 "notary-filter",
									 "notary-phone",
									 "notary-search",
								]
                    }
					 }
				) {
					edges {
						node {
							name
							childImageSharp {
								fluid(maxWidth: 930) {
									...GatsbyImageSharpFluid
									originalImg
									presentationWidth
									presentationHeight
								}
							}
						}
					}
				}
			}
		`)
		return <Component {...props} images={images} />
	}

	return Enhanced
}

export default WithDbCompany
