import React, { useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Reviews.module.scss'
import Review from './components/Review'
import Button from 'components/Button'
import ymReachGoal from 'utils/ymReachGoal'

const b = bem.reviews(css)

const Reviews = props => {
	const { reviews, limit, showBy } = props
	const count = showBy || limit

	const [currentLimit, setCurrentLimit] = useState(limit)

	if (!reviews || !reviews.length) return null

	const rest = reviews.length - currentLimit
	return (
		<div className={b()}>
			{reviews.map((review, index) => {
				return (
					<div key={review.id} className={b('item', currentLimit && index + 1 > currentLimit ? 'invisible' : '')}>
						<Review key={review.contact} {...review} />
					</div>
				)
			})}
			{!!(currentLimit && reviews.length > currentLimit) && (
				<div className="text-center">
					<Button
						size="sm"
						onClick={() => {
							setCurrentLimit(currentLimit + count)
							ymReachGoal('click-reviews')
						}}
					>
						Показать еще {rest > count ? count : rest} из {reviews.length}
					</Button>
				</div>
			)}
		</div>
	)
}

Reviews.defaultProps = {
	limit: 0,
}

Reviews.propTypes = {
	reviews: PropTypes.arrayOf(PropTypes.object),
	limit: PropTypes.number,
	showBy: PropTypes.number,
}

export default Reviews
