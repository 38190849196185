import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Review.module.scss'
import LinkDuplicate from 'components/LinkDuplicate'
import Secondary from 'components/Secondary'
import declension from 'utils/declension'

const b = bem.review(css)

const Review = props => {
	const { name, contact, text, comment, databases, isMoreDatabases, type, images, audio, className } = props
	const method = {
		email: 'через почту',
		whatsapp: 'через whatsapp',
		telegram: 'через телеграм',
		phone: 'через телефон',
		viber: 'через viber',
		website: 'через веб-сайт',
		kwork: 'на фриланс-бирже',
	}[type]

	return (
		<div className={b({}, className)}>
			<div className={b('header')}>
				{name && <span className={b('name')}>{name}</span>}
				{contact}
			</div>
			<blockquote className={b('text')} dangerouslySetInnerHTML={{ __html: text }} />
			{comment && (
				<div className={b('comment')}>
					<div className={b('comment-name')}>Комментарий администратора:</div>
					<blockquote className={b('comment-text')}>{comment}</blockquote>
				</div>
			)}
			<div className={b('footer')}>
				<Secondary className={b('meta')}>
					<p>
						Клиент приобрел {declension(databases.length, 'базы', 'базу', 'базы')}:{' '}
						{databases.map((database, index) => {
							const name = database.name.toLowerCase()
							const separator = index !== databases.length - 1 ? ', ' : ''
							if (!database.url) return name + separator
							return (
								<React.Fragment key={name}>
									<LinkDuplicate to={database.url} title={`База данных «${database.name}»`}>
										{name}
									</LinkDuplicate>
									{separator}
								</React.Fragment>
							)
						})}
						{isMoreDatabases && ' и другие'}
					</p>
					<p>Отзыв написан {method}</p>
				</Secondary>
				<div className={b('attachments')}>
					{!!images.length && (
						<div className={b('images')}>
							{images.map((image, index) => (
								<a
									key={image.id}
									className={b('image')}
									href={image.sourceUrl}
									title="Посмотреть скриншот отзыва"
									target="_blank"
									rel="nofollow noopener noreferrer"
								>
									<img
										src={image.sourceUrl}
										srcSet={image.srcSet}
										alt={
											image.altText ||
											`Скриншот ${index + 1} отзыва с клиентом${
												name ? ' ' + name : ''
											} ${contact}, написанный ${method}`
										}
									/>
								</a>
							))}
						</div>
					)}
					{audio && (
						/* eslint-disable jsx-a11y/media-has-caption */
						<audio controls preload="none" className={b('audio')}>
							<source src={audio.mediaItemUrl} type={audio.mimeType} />
							Ваш браузер не поддерживает аудио-файлы
						</audio>
					)}
				</div>
			</div>
		</div>
	)
}

Review.propTypes = {
	id: PropTypes.string.isRequired,
	reviewId: PropTypes.number.isRequired,
	name: PropTypes.string,
	contact: PropTypes.string,
	text: PropTypes.string.isRequired,
	comment: PropTypes.string,
	type: PropTypes.oneOf(['email', 'whatsapp', 'telegram', 'phone', 'viber', 'website', 'kwork']).isRequired,
	databases: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			url: PropTypes.string,
		}).isRequired
	).isRequired,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sourceUrl: PropTypes.string.isRequired,
			srcSet: PropTypes.string.isRequired,
			altText: PropTypes.string,
		})
	).isRequired,
	audio: PropTypes.shape({
		mediaItemUrl: PropTypes.string.isRequired,
		mimeType: PropTypes.string.isRequired,
	}),
	isMoreDatabases: PropTypes.bool,
}

export default Review
