/**
 * Получение перемешанного массива чисел на основе ключа
 * @param key
 * @param length
 */
const shuffleStatic = (key, length) => {
	let numbers = []
	let hash = ''

	while (numbers.length < length) {
		hash += _stringToHash(hash || key)

		// Разделение строки на массив с тремя цифрами
		numbers = hash.match(/..?.?/g).map(Number)

		// Удаляем дубли
		numbers = [...new Set(numbers)]
	}

	// Удаление лишних значений
	numbers = numbers.splice(0, length)

	// Кеширование индексов чисел в массиве
	const indexes = Object.fromEntries(Object.entries({ ...numbers }).map(a => a.reverse()))

	// Сортируем
	numbers.sort((a, b) => (a > b ? 1 : -1))

	// Записываем индексы отсортированного массива
	let res = []
	numbers.forEach(v => {
		res.push(Number(indexes[v]))
	})

	return res
}

/**
 * Числовой хеш из строки
 * @param string
 * @returns {string|number}
 * @private
 */
function _stringToHash(string) {
	let hash = 0

	if (string.length === 0) return hash

	for (let i = 0; i < string.length; i++) {
		const char = string.charCodeAt(i)
		hash = (hash << 5) - hash + char
		hash = hash & hash
	}

	hash = String(Math.abs(hash))

	return hash
}

module.exports = shuffleStatic
