import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Gallery.module.scss'
import lodash from 'utils/lodash'
import Img from 'gatsby-image'

const b = bem.gallery(css)

/**
1	[1]
2	[1, 2]
3	[1, 2, 3]
4	[1, 2] [3, 4]
5	[1, 2, 3] [4, 5] *
6	[1, 2, 3] [4, 5, 6] *
7	[1, 2, 3] [4, 5] [6, 7]
8	[1, 2, 3] [4, 5, 6] [7, 8] *
9	[1, 2, 3] [4, 5, 6] [7, 8, 9] *
10	[1, 2, 3] [4, 5, 6] [7, 8] [9, 10]
11	[1, 2, 3] [4, 5, 6] [7, 8, 9] [10, 11] *
12	[1, 2, 3] [4, 5, 6] [7, 8, 9] [10, 11, 12] *
 * @param array
 */
const getGroups = array => {
	const len = array.length
	if (len < 4) return [array]

	let res

	// 5, 8, 11, 14
	if ((len - 2) % 3 === 0) {
		res = lodash.chunk(array, 3)
	}

	// 6, 9, 12, 15
	if ((len - 3) % 3 === 0) {
		res = lodash.chunk(array, 3)
	}

	// 4, 7, 10, 13, 16
	if ((len - 4) % 3 === 0) {
		const first = array.slice(0, -4)
		const last = array.slice(-4)
		res = lodash.chunk(first, 3).concat(lodash.chunk(last, 2))
	}

	return res
}

const Gallery = props => {
	const { images } = props
	if (!images.length) return null
	const groups = getGroups(images)

	// Приведение всех изображений к подгруппам к одинаковой высоте
	groups.forEach(group => {
		if (group.length === 1) return
		let widths = []
		group.forEach(data => {
			const relativeHeight = group[0].image.presentationHeight / data.image.presentationHeight
			const width = data.image.presentationWidth * relativeHeight
			widths.push(width)
		})
		const full = widths.reduce((sum, width) => sum + width, 0)
		group.forEach((data, index) => {
			data.image.calcWidth = ((widths[index] * 100) / full).toFixed(2)
		})
	})

	/* eslint-disable react/jsx-no-target-blank */
	return (
		<div className={b()}>
			{groups.map((group, gIndex) => {
				return (
					<div className={b('group', { size: group.length })} key={`group-${gIndex}`}>
						{group.map((data, dIndex) => {
							const style = {}
							data.image.calcWidth && (style.width = `${data.image.calcWidth}%`)
							return (
								<div className={b('wrap')} style={style} key={`image-${dIndex}`}>
									<figure className={b('image', { 'is-caption': !!data.caption })}>
										<a className={b('link')} href={data.image.originalImg} title={data.title} target="_blank">
											<Img fluid={data.image} alt={data.alt} />
										</a>
										{data.caption && <figcaption className={b('caption')}>{data.caption}</figcaption>}
									</figure>
								</div>
							)
						})}
					</div>
				)
			})}
		</div>
	)
}

Gallery.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.object.isRequired,
			alt: PropTypes.string.isRequired,
			title: PropTypes.string,
			caption: PropTypes.string,
		})
	).isRequired,
}

export default Gallery
